export default [
  {
    header: 'partner',
  },
  {
    title: 'Partner Portal',
    icon: 'BookmarkIcon',
    children: [{
      title: 'My Organizations',
      route: 'partner-organizations',
      icon: 'BriefcaseIcon',
      resource: 'Mentor',
      action: 'read',
    },
    {
      title: 'Manage Incubator',
      route: 'partner-incubators',
      icon: 'CompassIcon',
      resource: 'Mentor',
      action: 'read',
    }],
  },
]
