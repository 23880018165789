export default [
  // {
  //   header: 'Partners',
  // },
  {
    title: 'Partners',
    route: 'manage-partners',
    icon: 'BookmarkIcon',
  },
]
