export default [
  {
    header: 'Manage Programs',
  },
  {
    title: 'Startup Programs',
    icon: 'PieChartIcon',
    route: 'manage-programs',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Skill Dev(Beta)',
    icon: 'UserIcon',
    route: 'manage-skill-dev-programs',
    resource: 'Incubator',
    action: 'read',
  },
]
