export default [
  {
    header: 'Home',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'My Incubator',
    icon: 'SlidersIcon',
    route: 'manage-incubator',
    resource: 'Incubator',
    action: 'read',
  },
  // {
  //   title: 'Growth',
  //   icon: 'TrendingUpIcon',
  //   route: 'manage-growth',
  //   resource: 'Incubator',
  //   action: 'read',
  // },
  // {
  //   title: 'Requests',
  //   icon: 'SendIcon',
  //   route: 'all-requests',
  //   resource: 'Incubator',
  //   action: 'read',
  // },
  // {
  //   title: 'Feedbacks',
  //   icon: 'ThumbsUpIcon',
  //   route: 'all-feedbacks',
  //   resource: 'Incubator',
  //   action: 'read',
  // },
  // {
  //   title: 'Finances',
  //   route: 'manage-finances',
  //   resource: 'Incubator',
  //   icon: 'SettingsIcon',
  //   action: 'read',
  // },
  {
    title: 'My Profile',
    route: 'my-profile',
    icon: 'UserIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'My Profile',
    route: 'my-profile',
    icon: 'UserIcon',
    resource: 'User',
    action: 'read',
  },
]
