export default [
  {
    header: 'Manage Ecosystem',
  },
  {
    title: 'Startups',
    route: 'manage-startups',
    icon: 'PieChartIcon',
  },
]
