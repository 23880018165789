export default [
  // {
  //   header: 'Students',
  // },
  {
    title: 'Students',
    route: 'manage-students',
    icon: 'UserIcon',
  },
]
