export default [
  {
    header: 'Student',
  },
  {
    title: 'Student Portal',
    icon: 'UserIcon',
    children: [
      {
        title: 'Manage Incubator',
        route: 'student-incubators',
        icon: 'CompassIcon',
        resource: 'User',
        action: 'read',
      },
      {
        title: 'Skill Dev Programs',
        icon: 'GridIcon',
        resource: 'User',
        action: 'read',
        children: [
          {
            title: 'Program',
            route: 'student-program',
            resource: 'User',
            action: 'read',
          },
          {
            title: 'Applications',
            route: 'student-applications',
            resource: 'User',
            action: 'read',
          },
          {
            title: 'Apply Now',
            route: 'student-apply-now',
            resource: 'User',
            action: 'read',
          },
        ],
      }],
  },

]
