export default [
  // {
  //   header: 'Investor Portal',
  // },
  // {
  //   title: 'Scout Startups',
  //   route: 'scout-startups',
  //   icon: 'SettingsIcon',
  // },
  // {
  //   title: 'Due Diligences',
  //   route: 'manage-duediligences',
  //   icon: 'SettingsIcon',
  // },
  // {
  //   title: 'Fundings',
  //   route: 'manage-fundings',
  //   icon: 'SettingsIcon',
  // },
  // {
  //   title: 'Create Purpose',
  //   route: 'add-duediligences',
  //   icon: 'PlusIcon',
  // },
  // {
  //   title: 'Add Funding',
  //   route: 'add-fundings',
  //   icon: 'PlusIcon',
  // },
]
