export default [
  {
    header: 'Mentor',
  },
  {
    title: 'Mentor Portal',
    icon: 'AwardIcon',
    children: [
      {
        title: 'Invitations',
        route: 'mentor-invitations',
        icon: 'UserPlusIcon',
        resource: 'Mentor',
        action: 'read',
      },
      {
        title: 'Program',
        route: 'mentor-programs',
        icon: 'GridIcon',
        resource: 'Mentor',
        action: 'read',
      },
      {
        title: 'Manage Incubator',
        route: 'mentor-incubators',
        icon: 'CompassIcon',
        resource: 'Mentor',
        action: 'read',
      },
    ],
  },
]
