export default [
  {
    header: 'Startup',
  },
  {
    title: 'Add Startup',
    icon: 'PlusIcon',
    route: 'startup-portal-details',
  },
  {
    title: 'Startup Portal',
    icon: 'PieChartIcon',
    children: [{
      title: 'My Startups',
      route: 'startup-portal-startups',
      icon: 'PieChartIcon',
      resource: 'User',
      action: 'read',
    },
    {
      title: 'Manage Incubator',
      route: 'startup-incubators',
      icon: 'CompassIcon',
      resource: 'User',
      action: 'read',
    },
    {
      title: 'Startup Programs',
      icon: 'GridIcon',
      resource: 'User',
      action: 'read',
      children: [
        {
          title: 'Program',
          route: 'startup-program',
          resource: 'User',
          action: 'read',
        },
        {
          title: 'Applications',
          route: 'startup-applications',
          resource: 'User',
          action: 'read',
        },
        {
          title: 'Apply Now',
          route: 'startup-apply-now',
          resource: 'User',
          action: 'read',
        },
      ],
    }],
  },

]
