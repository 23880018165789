export default [
  // {
  //   header: 'Mentors',
  // },
  {
    title: 'Mentors',
    route: 'manage-mentors',
    icon: 'AwardIcon',
  },
]
