export default {
  // Endpoints
  loginEndpoint: 'https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/api/token/',
  registerEndpoint: 'https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/users/register/',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
